@use "breakpoints.scss";


.noScreenshot {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: var(--brandLight7);
    //border: 1px dashed var(--gray300);
    color: var(--metaLight);
    width: 100%;
    padding: 0;
    filter: none;
    font-size: 30px;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--metaLight);
        //height: 197px;
        width: 100%;


    }
}

/* Applies to very large screens (+1200px) */
@media screen and (breakpoints.$xlUp) {
    .noScreenshot {
        box-shadow: none !important;

        //border-left: 1px solid var(--brandLight4);

        &.large {
            min-width: 309px;
            min-height: 200px;
            background-color: var(--ctaLightest);
            border: 1px dashed var(--ctaLight);

            svg {
                color: var(--ctaMain);
                width: 70px;
                height: 70px;
            }
        }

        a {
            height: 197px;
        }
    }
}